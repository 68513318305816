import {GameType, GAMETYPES} from "@/enum/GameTypes";

export const DEEPLINK = {
    // NONE is only a helper and used in project only
    // '#' doesn't represent anything here but local rules require non empty value
    NONE: {name: "keine zusätzliche Verlinkung", serverIdentifier: "#"},
    URL: {name: "URL", serverIdentifier: "URL"},
    SPIELSCHEIN: {name: "Spielschein", serverIdentifier: "DeeplinkContent"},
    QUICKTIPP: {name: "Quicktipp", serverIdentifier: "quicktipp"},
};

export const SPIELSCHEIN_DEEPLINK_ACTIONS = {
    LOTTO: {action: "DL_VALUE_Jackpot_Lotto"},
    EUROJACKPOT: {action: "DL_VALUE_Jackpot_Euro"},
    GSP: {action: "DL_VALUE_gs"},
    GSP_JAHRELOS: {action: "DL_VALUE_gs_jahreslos"},
    KENO: {action: "DL_VALUE_KENO"},
    BINGO: {action: "DL_VALUE_BINGO"},
    BAYERNMILLIONEN: {action: "DL_VALUE_byMio"}
};

//On these you need to append the number representating the value of the QT
export const QUICKTIPP_DEEPLINK_ACTIONS = {
    LOTTO: {action: "DL_VALUE_Jackpot_Lotto_QT_"},
    EUROJACKPOT: {action: "DL_VALUE_Jackpot_Euro_QT_"},
};

export function deeplinkcontentActionForGame(name: GameType | null): string | null {
    switch (name) {
        case GAMETYPES.LOTTO:
            return SPIELSCHEIN_DEEPLINK_ACTIONS.LOTTO.action;
        case GAMETYPES.EUROJACKPOT:
            return SPIELSCHEIN_DEEPLINK_ACTIONS.EUROJACKPOT.action;
        case GAMETYPES.GLUECKSSPIRALE:
            return SPIELSCHEIN_DEEPLINK_ACTIONS.GSP.action;
        case GAMETYPES.GLUECKSSPIRALE_JAHRESLOS:
            return SPIELSCHEIN_DEEPLINK_ACTIONS.GSP_JAHRELOS.action;
        case GAMETYPES.KENO:
            return SPIELSCHEIN_DEEPLINK_ACTIONS.KENO.action;
        case GAMETYPES.BINGO:
            return SPIELSCHEIN_DEEPLINK_ACTIONS.BINGO.action;
        case GAMETYPES.BAYERNMILLIONEN:
            return SPIELSCHEIN_DEEPLINK_ACTIONS.BAYERNMILLIONEN.action;
        default:
            return null
    }
}

export function maskedDeeplinkActionForAction(action: string | null): string | null {
    let maskedAction = action;

    switch (action) {
        case SPIELSCHEIN_DEEPLINK_ACTIONS.LOTTO.action:
        case QUICKTIPP_DEEPLINK_ACTIONS.LOTTO.action:
            maskedAction = GAMETYPES.LOTTO.displayTitle + " Normal";
            break;
        case SPIELSCHEIN_DEEPLINK_ACTIONS.EUROJACKPOT.action:
        case QUICKTIPP_DEEPLINK_ACTIONS.EUROJACKPOT.action:
            maskedAction = GAMETYPES.EUROJACKPOT.displayTitle + " Normal";
            break;
        case SPIELSCHEIN_DEEPLINK_ACTIONS.GSP.action:
            maskedAction = GAMETYPES.GLUECKSSPIRALE.displayTitle;
            break;
        case SPIELSCHEIN_DEEPLINK_ACTIONS.GSP_JAHRELOS.action:
            maskedAction = GAMETYPES.GLUECKSSPIRALE_JAHRESLOS.displayTitle;
            break;
        case SPIELSCHEIN_DEEPLINK_ACTIONS.KENO.action:
            maskedAction = GAMETYPES.KENO.displayTitle;
            break;
        case SPIELSCHEIN_DEEPLINK_ACTIONS.BINGO.action:
            maskedAction = GAMETYPES.BINGO.displayTitle;
            break;
        case SPIELSCHEIN_DEEPLINK_ACTIONS.BAYERNMILLIONEN.action:
            maskedAction = GAMETYPES.BAYERNMILLIONEN.displayTitle;
            break;
    }
    return maskedAction
}


export function quicktippActionForGame(name: GameType | null): string | null {
    switch (name) {
        case GAMETYPES.LOTTO:
            return QUICKTIPP_DEEPLINK_ACTIONS.LOTTO.action;
        case GAMETYPES.EUROJACKPOT:
            return QUICKTIPP_DEEPLINK_ACTIONS.EUROJACKPOT.action;
        default:
            return null
    }
}

export function deeplinkTypeForAction(action: string | null, maskedType: boolean): string | null {
    let type = null;

    switch (action) {
        case SPIELSCHEIN_DEEPLINK_ACTIONS.LOTTO.action:
        case SPIELSCHEIN_DEEPLINK_ACTIONS.EUROJACKPOT.action:
        case SPIELSCHEIN_DEEPLINK_ACTIONS.GSP.action:
        case SPIELSCHEIN_DEEPLINK_ACTIONS.GSP_JAHRELOS.action:
        case SPIELSCHEIN_DEEPLINK_ACTIONS.KENO.action:
        case SPIELSCHEIN_DEEPLINK_ACTIONS.BINGO.action:
        case SPIELSCHEIN_DEEPLINK_ACTIONS.BAYERNMILLIONEN.action:
            if (maskedType) {
                type = DEEPLINK.SPIELSCHEIN.name;
            } else {
                type = DEEPLINK.SPIELSCHEIN.serverIdentifier;
            }
            break;
        case QUICKTIPP_DEEPLINK_ACTIONS.LOTTO.action:
        case QUICKTIPP_DEEPLINK_ACTIONS.EUROJACKPOT.action:
            if (maskedType) {
                type = DEEPLINK.QUICKTIPP.name;
            } else {
                type = DEEPLINK.QUICKTIPP.serverIdentifier;
            }
            break;
    }

    //If action at this point is still null check if it has any well, then it must be URL
    if (type == null && (action?.length ?? 0 > 0)) {
        if (maskedType) {
            type = DEEPLINK.URL.name;
        } else {
            type = DEEPLINK.URL.serverIdentifier;
        }
    }

    return type
}

export function isUrlDeeplink(action: string | null): boolean {
    return action !== SPIELSCHEIN_DEEPLINK_ACTIONS.LOTTO.action &&
        action !== SPIELSCHEIN_DEEPLINK_ACTIONS.EUROJACKPOT.action &&
        action !== SPIELSCHEIN_DEEPLINK_ACTIONS.GSP.action &&
        action !== SPIELSCHEIN_DEEPLINK_ACTIONS.GSP_JAHRELOS.action &&
        action !== SPIELSCHEIN_DEEPLINK_ACTIONS.KENO.action &&
        action !== SPIELSCHEIN_DEEPLINK_ACTIONS.BINGO.action &&
        action !== SPIELSCHEIN_DEEPLINK_ACTIONS.BAYERNMILLIONEN.action &&
        action !== QUICKTIPP_DEEPLINK_ACTIONS.LOTTO.action &&
        action !== QUICKTIPP_DEEPLINK_ACTIONS.EUROJACKPOT.action &&
        (action?.length ?? 0) > 0;
}

export function isSpielscheinDeeplink(action: string | null): boolean {
    return action === SPIELSCHEIN_DEEPLINK_ACTIONS.LOTTO.action ||
        action === SPIELSCHEIN_DEEPLINK_ACTIONS.EUROJACKPOT.action ||
        action === SPIELSCHEIN_DEEPLINK_ACTIONS.GSP.action ||
        action === SPIELSCHEIN_DEEPLINK_ACTIONS.GSP_JAHRELOS.action ||
        action === SPIELSCHEIN_DEEPLINK_ACTIONS.KENO.action ||
        action === SPIELSCHEIN_DEEPLINK_ACTIONS.BINGO.action ||
        action === SPIELSCHEIN_DEEPLINK_ACTIONS.BAYERNMILLIONEN.action;
}

export function isQuicktippDeeplink(action: string | null): boolean {
    return action === QUICKTIPP_DEEPLINK_ACTIONS.LOTTO.action ||
        action === QUICKTIPP_DEEPLINK_ACTIONS.EUROJACKPOT.action;
}
