export const VALUES = {
    MAX_TABS_IN_NAVIGATION: 5,
    PERSISTED_AUTH: 'auth',
    LOADING_DELAY: 500,
    SNACKBAR_TIMEOUT: 2500,
    DATE_TIME_FORMAT: 'DD.MM.YYYY HH:mm',
    DATE_FORMAT: 'DD.MM.YYYY',
    TIME_FORMAT: 'HH:mm',
    CAMPAIGNS_MAX_COUNT: 2, //Brandenburg only
    QUICKTIPPS_PER_LOTTERY_MAX_COUNT: 3 //Brandenburg only
};
