
import {Component} from "vue-property-decorator";
import HomeAppBar from "../components/AppBar.vue";
import Vue from "vue";
import {namespace} from "vuex-class";
import AppversionDialog from "@/components/dialogs/appversion/AppversionDialog.vue";
import AppListRow from '@/components/AppListRow.vue';
import {AppversionDialogModel, AppversionModel} from "@/stores/appversions/types";
import {OrgConfig} from "@/stores/auth/types";

const authModule = namespace('auth');
const AppversionsModule = namespace('appversions');

@Component({
  components: {
    HomeAppBar,
    AppversionDialog,
    AppListRow
  },
})
export default class Appversions extends Vue {
  @authModule.Getter('config') userConfig!: OrgConfig;

  @AppversionsModule.Action('getAll') fetchAppversions!: Function;
  @AppversionsModule.State('appversions_ios') appversions_ios!: Array<AppversionModel>;
  @AppversionsModule.State('appversions_android') appversions_android!: Array<AppversionModel>;
  @AppversionsModule.State('appDialogState') AppversionDialog!: AppversionDialogModel;
  @AppversionsModule.Mutation('showAppversionDialog') showDialog!: Function;

  search = '';
  tabs = null;
  activeTab = 'ios';
  footerProps = {
    'items-per-page-options': [5, 10, 20, -1]
  };

  get headers() {
    //TODO some criteria from backend on which org we are

    const headersArray = [];
    headersArray.push({text: '', align: 'left', width: 64, value: null, sortable: false});
    headersArray.push({text: 'Appversion', align: 'left', value: 'version', sortable: true});
    headersArray.push({text: 'Download URL', align: 'left', value: 'download_url', sortable: true});
    headersArray.push({text: 'Upload Text', align: 'left', value: 'version_info', sortable: true});
    headersArray.push({text: 'Aktiv?', align: 'left', value: 'active', sortable: true});
    if (this.userConfig.has_forced_update)
      headersArray.push({text: 'Zwangsupdate', align: 'left', value: 'forced', sortable: true});
    headersArray.push({text: 'Optionen', align: 'left', value: 'option', sortable: false});
    return headersArray;
  }

  mounted() {
    this.fetchAppversions(this.activeTab)
  }

  onNewAppversion() {
    this.showDialog({appversion: null, isNew: true, tab: this.activeTab})
  }

  changeTab(tab: string) {
    this.activeTab = tab
  }

}

