
import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {PushModel} from "@/stores/push/types";
import timeUtil from "@/utils/time";
import {identifyPlatform, PLATFORMS} from "@/enum/Platforms";
import {PUSHTYPES} from "@/enum/PushTypes";

const pushModule = namespace('push');

@Component({
  name: 'push-list-row'
})
export default class PushListRow extends Vue {
  @pushModule.Mutation('showDialog') showDialog!: Function;

  @Prop({type: Object, required: true, default: {}}) readonly push!: PushModel;

  identifierAndroid = PLATFORMS.ANDROID.serverIdentifier;
  identifierIOS = PLATFORMS.IOS.serverIdentifier;
  typeEDITORIAL = PUSHTYPES.EDITORIAL.name;

  icon(): string | null {
    switch (identifyPlatform(this.push.os)) {
      case PLATFORMS.ANDROID:
        return 'mdi-android'
      case PLATFORMS.IOS:
        return 'mdi-apple'
      default:
        return null
    }
  }

  iconColor(): string | null {
    switch (identifyPlatform(this.push.os)) {
      case PLATFORMS.ANDROID:
        return 'android'
      case PLATFORMS.IOS:
        return 'apple'
      default:
        return null
    }
  }


  formatTimestamp(date: string): string {
    return timeUtil.formattedDate(date)
  }

  sendClicked() {
    this.showDialog({
      push: this.push,
      isNew: false
    })
  }
}
