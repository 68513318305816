
import {Component} from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: 'PageManagement',
  components: {
    //
  },
})
export default class PageManagement extends Vue {

}
