
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {namespace} from "vuex-class";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {DocumentModel} from "@/stores/documents/types";
import timeUtil from "@/utils/time"

const documentsModule = namespace('documents');

Vue.filter('formatDate', function (value: any) {
  if (value) {
    return timeUtil.formattedDate(value)
  }
});

@Component({
  name: 'doc-list-row',
})

export default class DocListRow extends Vue {
  @documentsModule.Mutation('showDocumentDialog') showDialog!: Function;
  @documentsModule.Action('delete') deleteDocument!: Function;
  @Prop({type: Object, required: true, default: {}}) readonly doc!: DocumentModel;

  get textClass(): string {
    return timeUtil.objectIsActive(this.doc.publish_at, this.doc.publish_until) ? 'black--text' : 'grey--text'
  }

  clicked(doc: DocumentModel) {
    if (doc.src) {
      window.open(doc.src)
    }
  }

  onEditDocument(doc: DocumentModel) {
    this.showDialog({document: doc, isNew: false})
  }

  onDeleteDocument(doc: DocumentModel) {
    QuestionStore.poseQuestion(
        null,
        `Dokument ${doc.title} wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex) => {
      if (answerIndex === 0) {
        this.deleteDocument({documentId: doc.document_id})
      }
    })
  }
}
