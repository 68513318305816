import Vue from 'vue'
import VueRouter, {NavigationGuardNext} from 'vue-router'
import Login from "@/pages/Login.vue";
import Campaigns from "@/pages/Campaigns.vue";
import Users from "@/pages/Users.vue";
import Documents from "@/pages/Documents.vue";
import Tickets from "@/pages/Tickets.vue";
import Quicktipps from "@/pages/Quicktipps.vue";
import Maintenance from "@/pages/Maintenance.vue";
import PageManagement from "@/pages/PageManagement.vue";
import Reports from "@/pages/Reports.vue";
import Appversions from "@/pages/Appversions.vue";
import SwitchDates from "@/pages/SwitchDates.vue";
import Push from "@/pages/Push.vue";
import {getLandingPageForOrganizationId, PATHS} from "@/constants/paths";
import {NAMES} from "@/constants/names";
import {ROLES} from "@/enum/Roles";
import {VALUES} from "@/constants/values";
import {AuthModel, User} from "@/stores/auth/types";
import {PRODUCTS} from "@/enum/Products";

Vue.use(VueRouter);

function getAuthData(): AuthModel | null {
    const stored = localStorage.getItem(VALUES.PERSISTED_AUTH);

    if (stored) {
        return JSON.parse(stored)
    }
    return null;
}

function isAdmin(user: User | undefined): boolean {
    switch (user?.role.role_id) {
        case ROLES.ADMIN.role_id:
        case ROLES.SUPERADMIN.role_id:
            return true
        default:
            return false
    }
}

function isSuperadmin(user: User | undefined): boolean {
    return user?.role.role_id === ROLES.SUPERADMIN.role_id
}


function handleBeforeEnter(authData: AuthModel | null, criteria: boolean, next: NavigationGuardNext) {
    if (authData) {
        if (criteria) {
            next()
        } else {
            next(getLandingPageForOrganizationId(authData.gesellschaft.ges_nr, authData.config.product_id == PRODUCTS.OA.name))
        }
    } else {
        // Reaching beforeEnter, these else cases shouldn't be possible
        next(PATHS.LOGIN)
    }
}

const router = new VueRouter({
    routes: [
        {
            path: PATHS.LOGIN,
            name: NAMES.LOGIN,
            component: Login
        },
        {
            path: PATHS.USERS,
            name: NAMES.USERS,
            component: Users,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(
                    authData,
                    (authData?.config?.has_user_management && isAdmin(authData?.user)) ?? false,
                    next
                )
            }
        },
        {
            path: PATHS.CAMPAIGNS,
            name: NAMES.CAMPAIGNS,
            component: Campaigns,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(authData, authData?.config?.has_campaign_management ?? false, next)
            }
        },
        {
            path: PATHS.DOCUMENTS,
            name: NAMES.DOCUMENTS,
            component: Documents,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(authData, authData?.config?.has_pdf_management ?? false, next)
            }
        },
        {
            path: PATHS.TICKETS,
            name: NAMES.TICKETS,
            component: Tickets,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(authData, authData?.config?.has_lot_management ?? false, next)
            }
        },
        {
            path: PATHS.QUICKTIPPS,
            name: NAMES.QUICKTIPPS,
            component: Quicktipps,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(authData, authData?.config?.has_simple_quicktipps ?? false, next)
            }
        },
        {
            path: PATHS.MAINTENANCE,
            name: NAMES.MAINTENANCE,
            component: Maintenance,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(authData, authData?.config?.has_maintenance ?? false, next)
            }
        },
        {
            path: PATHS.PAGEMANAGEMENT,
            name: NAMES.PAGEMANAGEMENT,
            component: PageManagement,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(authData, authData?.config?.has_page_management ?? false, next)
            }
        },
        {
            path: PATHS.REPORTS,
            name: NAMES.REPORTS,
            component: Reports,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(
                    authData,
                    (authData?.config?.has_report_management && isAdmin(authData?.user)) ?? false,
                    next
                )
            }
        },
        {
            path: PATHS.APPVERSIONS,
            name: NAMES.APPVERSIONS,
            component: Appversions,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(authData, authData?.config?.app_version ?? false, next)
            }
        },
        {
            path: PATHS.SWITCHDATES,
            name: NAMES.SWITCHDATES,
            component: SwitchDates,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(authData, authData?.config?.has_switch_management ?? false, next)
            }
        },
        {
            path: PATHS.PUSH,
            name: NAMES.PUSH,
            component: Push,
            beforeEnter: (to, from, next) => {
                const authData = getAuthData()
                handleBeforeEnter(
                    authData,
                    isSuperadmin(authData?.user) || (authData?.config?.has_editorial_push ?? false),
                    next
                )
            }
        }
    ]
});

export default router;

// Navigation Resolution Flow
// https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
router.beforeEach((to, from, next) => {
    const authData = getAuthData()
    const token = authData?.auth_token;

    if (to.name === NAMES.LOGIN) {
        if (token) {
            // logged in get directed to landing Page of Org
            return next(getLandingPageForOrganizationId(authData?.gesellschaft.ges_nr, authData?.config.product_id == PRODUCTS.OA.name))
        }
    }
    return next();
});
