
import Vue from 'vue';
import {Component, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";

import {getLandingPageForOrganizationId, PATHS} from "@/constants/paths";

import HomeAppBar from "@/components/AppBar.vue";
import InfoDialog from "@/components/dialogs/info/InfoDialog.vue";
import QuestionDialog from "@/components/dialogs/question/QuestionDialog.vue";
import AppSnackbar from "@/components/dialogs/snackbar/AppSnackbar.vue";
import {VALUES} from "@/constants/values";
import {Gesellschaft, OrgConfig, User} from "@/stores/auth/types";
import {PRODUCTS} from "@/enum/Products";
import SiteSettings from "@/components/Settings.vue";
import {ROLES} from "@/enum/Roles";

const authModule = namespace('auth');

@Component({
  components: {
    SiteSettings,
    HomeAppBar,
    InfoDialog,
    QuestionDialog,
    AppSnackbar,
  },
})
export default class App extends Vue {
  @authModule.State('auth_token') authToken: string | undefined;
  //Not mapped to Organization
  @authModule.State('gesellschaft') gesellschaft: Gesellschaft | undefined;
  @authModule.State('config') config: OrgConfig | undefined;
  @authModule.State('user') user: User | undefined;

  @authModule.Mutation('setAuthentication') setAuth!: Function;

  superAdminRoleId = ROLES.SUPERADMIN.role_id

  @Watch('authToken')
  onAuthTokenChanged(val: string | undefined, oldVal: string | undefined) {
    if (!oldVal && val) {
      setTimeout(() => {
            this.$router.push(getLandingPageForOrganizationId(this.gesellschaft?.ges_nr, this.config?.product_id == PRODUCTS.OA.name));
          },
          VALUES.LOADING_DELAY
      );
    } else if (!val) {
      this.$router.push(PATHS.LOGIN);
    }
  }

  mounted() {
    //Check local storage
    const stored = localStorage.getItem(VALUES.PERSISTED_AUTH);
    if (stored) {
      this.setAuth(JSON.parse(stored))

      const mode = localStorage.getItem('mode')
      if (mode && this.user?.role?.role_id === this.superAdminRoleId) {
        this.$vuetify.theme.dark = mode === 'dark'
      }
    }
  }
}
