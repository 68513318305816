
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {AppversionModel} from "@/stores/appversions/types";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {OrgConfig} from "@/stores/auth/types";

const authModule = namespace('auth');
const AppversionsModule = namespace('appversions');

@Component({
  name: 'app-list-row'
})
export default class AppListRow extends Vue {
  @authModule.Getter('config') userConfig!: OrgConfig;

  @AppversionsModule.Mutation('showAppversionDialog') showDialog!: Function;
  @AppversionsModule.Action('delete') deleteAppversion!: Function;
  @Prop({type: Object, required: true, default: {}}) readonly appversion!: AppversionModel;

  onEditAppversion() {
    this.showDialog({appversion: this.appversion, isNew: false})
  }

  onDeleteAppversion() {
    QuestionStore.poseQuestion(
        null,
        `App ${this.appversion.version} wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex) => {
      if (answerIndex === 0) {
        this.deleteAppversion({appId: this.appversion.id})
      }
    })
  }
}

