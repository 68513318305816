
import Vue from "vue";
import Vuetify from 'vuetify/lib'
import {Component, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {AppversionDialogModel} from "@/stores/appversions/types";
import {OrgConfig} from "@/stores/auth/types";
import {Bold, Italic, TiptapVuetify, TiptapVuetifyPlugin} from "tiptap-vuetify";
import 'tiptap-vuetify/dist/main.css';
import 'vuetify/dist/vuetify.min.css';

const authModule = namespace('auth');
const AppversionsModule = namespace('appversions');

const opts = {}
const vuetify = new Vuetify(opts);

Vue.use(TiptapVuetifyPlugin, {
// the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
// optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
});

@Component({
  name: 'AppversionDialog',
  components: {
    TiptapVuetify
  }
})
export default class AppversionDialog extends Vue {
  @authModule.Getter('config') userConfig!: OrgConfig;

  @AppversionsModule.State('appDialogState') state!: AppversionDialogModel;
  @AppversionsModule.Mutation('dismissAppversionDialog') cancelDialog!: Function;
  @AppversionsModule.Action('create') saveAppversion!: Function;
  @AppversionsModule.Action('update') updateAppversion!: Function;

  loadActive = false

  platform = '';
  version = '';
  download_url = '';
  version_info = '';
  active = false;
  forced = false;
  system = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extensions: any[] = [];

  get dialogTitle() {
    if (!this.state.isNew && this.state.visible) {
      return 'Appversion bearbeiten'
    } else if (this.state.isNew && this.state.visible) {
      return 'Neue Appversion'
    } else {
      return ''
    }
  }

  get disabled() {
    return !this.state.isNew;
  }

  @Watch('state.visible')
  getExtensions() {
    if (this.userConfig.product_id === 'online') {
      this.extensions = [Italic, Bold];
    }
  }

  onCancel() {
    this.cancelDialog();
    this.platform = '';
    this.version = '';
    this.download_url = '';
    this.version_info = '';
    this.active = false;
    this.forced = false;
    this.system = '';
  }

  onConfirm() {
    if (this.state.isNew) {
      const data = {
        'platform': this.state.tab,
        'download_url': this.download_url,
        'version': this.version,
        'version_info': this.version_info,
        'active': this.active,
        'forced': this.forced
      };
      this.saveAppversion(data).then(() => {
        this.onCancel()
      });
    } else {
      const data = {
        'platform': this.platform,
        'download_url': this.download_url,
        'version': this.version,
        'version_info': this.version_info,
        'active': this.active,
        'forced': this.forced,
        'id': this.state.appversion?.id
      };
      this.updateAppversion(data).then(() => {
        this.onCancel()
      });
    }
  }

  @Watch('state.visible')
  onShowDialog() {
    if (!this.state.isNew) {
      if (this.state.appversion?.platform) {
        this.platform = this.state.appversion?.platform
      }
      if (this.state.appversion?.version) {
        this.version = this.state.appversion?.version
      }
      if (this.state.appversion?.download_url) {
        this.download_url = this.state.appversion?.download_url
      }
      if (this.state.appversion?.version_info) {
        this.version_info = this.state.appversion?.version_info
      }
      if (this.state.appversion?.active) {
        this.active = this.state.appversion?.active
      }
      if (this.state.appversion?.forced) {
        this.forced = this.state.appversion?.forced
      }
    }
  }

  @Watch('loading')
  onLoadingChanged(val: boolean, oldVal: boolean) {
    this.loadActive = val
  }

}

