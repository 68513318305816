export const ROUTES = {
    AUTH: 'admin/auth/',
    LOGIN: 'admin/login/',
    LOGOUT: 'admin/logout/',

    // Users
    ALL_USERS: 'admin/users/',
    UPDATE_USER: 'admin/users/{user_id}',

    //Campaigns
    CAMPAIGNS_GET_ALL: 'admin/campaigns/',
    CAMPAIGNS_GET_SINGLE: 'admin/campaigns/{campaign_id}',
    CAMPAIGNS_CREATE_NEW: 'admin/campaigns/',
    CAMPAIGNS_UPDATE_EXISTING: 'admin/campaigns/{campaign_id}',
    CAMPAIGNS_DELETE_EXISTING: 'admin/campaigns/{campaign_id}',

    //Campaigns Images
    CAMPAIGNS_IMAGES_GET_ALL: 'admin/campaigns/{campaign_id}/images/',
    CAMPAIGNS_IMAGES_CREATE_NEW: 'admin/campaigns/{campaign_id}/images/',
    CAMPAIGNS_IMAGES_GET_SINGLE: 'admin/campaigns/{campaign_id}/images/{image_id}',
    CAMPAIGNS_IMAGES_UPDATE: 'admin/campaigns/{campaign_id}/images/{image_id}',
    CAMPAIGNS_IMAGES_DELETE: 'admin/campaigns/{campaign_id}/images/{image_id}',

    //Campaigns Quicktipps (only used by BB as they only service quicktipp deeplinks)
    CAMPAIGNS_QUICKTIPPS_GET_ALL: 'admin/campaigns/{campaign_id}/quicktipps/',
    CAMPAIGNS_QUICKTIPPS_CREATE_NEW: 'admin/campaigns/{campaign_id}/quicktipps/',
    CAMPAIGNS_QUICKTIPPS_GET_SINGLE: 'admin/campaigns/{campaign_id}/quicktipps/{quicktipp_id}',
    CAMPAIGNS_QUICKTIPPS_UPDATE: 'admin/campaigns/{campaign_id}/quicktipps/{quicktipp_id}',
    CAMPAIGNS_QUICKTIPPS_DELETE: 'admin/campaigns/{campaign_id}/quicktipps/{quicktipp_id}',

    //Campaigns Deeplinks
    //Deeplink routes restricts to one Object (Deeplink & (optional) Quicktipp) at a time
    CAMPAIGNS_DEEPLINK_UNIVERSAL: 'admin/campaigns/{campaign_id}/deeplink/',
    CAMPAIGNS_DEEPLINK_QUICKTIPP_UNIVERSAL: 'admin/campaigns/{campaign_id}/deeplink/quicktipp/',

    //'Simple' Quicktipps
    QUICKTIPPS_GET_ALL: 'admin/quicktipps/',
    QUICKTIPPS_CREATE_NEW: 'admin/quicktipps/',
    QUICKTIPPS_GET_SINGLE: 'admin/quicktipps/{quicktipp_id}',
    QUICKTIPPS_UPDATE: 'admin/quicktipps/{quicktipp_id}',
    QUICKTIPPS_DELETE: 'admin/quicktipps/{quicktipp_id}',

    //Documents
    DOCUMENTS_GET_ALL: 'admin/pdfs/documents',
    DOCUMENTS_UPLOAD: 'admin/pdfs/documents/upload',
    DOCUMENTS_CREATE_NEW: 'admin/pdfs/documents',
    DOCUMENT_UPDATE_EXISTING: 'admin/pdfs/documents',
    DOCUMENT_DELETE_EXISTING: 'admin/pdfs/documents/{document_id}',
    DOCUMENT_UPLOAD: 'admin/pdfs/documents/upload',

    //Tickets
    TICKETS_GET_ALL: 'admin/lose/',
    TICKETS_CREATE_NEW: 'admin/lose/',
    TICKETS_UPDATE_EXISTING: 'admin/lose/{lot_id}',
    TICKETS_DELETE_EXISTING: 'admin/lose/{lot_id}',
    TICKETS_DELETE_IMAGE: 'admin/lose/{lot_id}/{image}',

    //Reports
    REPORTS_GET_ALL: 'admin/reports/',
    REPORTS_GET_SINGLE: 'admin/reports/{report_id}',
    REPORTS_DELETE: 'admin/reports/{report_id}',

    //Appversions
    APPVERSIONS_GET_ALL: 'admin/appversions/',
    APPVERSIONS_CREATE_NEW: 'admin/appversions/',
    APPVERSIONS_UPDATE_EXISTING: 'admin/appversions/{app_id}',
    APPVERSIONS_DELETE_EXISTING: 'admin/appversions/{app_id}',

    //SwitchDates - we use all request instead of singular ones
    SWITCHDATES_GET_ALL: 'api/{ges_nr}/switchdates/all-switches',
    SWITCHDATES_POST_EJ_SWITCH: 'admin/switchdates/ej-switch',
    SWITCHDATES_POST_EJ_FIRST_DRAW_NEW: 'admin/switchdates/ej-first-draw-switch',
    SWITCHDATES_POST_FEE_UPDATE: 'admin/switchdates/fee-update',

    //Push
    SEND_EDITORIAL_PUSH: 'admin/editorial_push',
    //Pushtest
    SEND_TEST_LOTTO_PUSH: 'admin/test_push_with_type',
    REPEAT_PUSH: 'admin/push_test_to_all',
    GET_ALL_LOTTO_PUSH: 'admin/push_notifications',
    GET_ALL_EDITORIAL_PUSH: 'admin/editorial_pushs',
};
