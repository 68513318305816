
import moment from 'moment'
import {Component, Model, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";

const DEFAULTS = {
  DISPLAY_FORMAT: 'DD.MM.YYYY - HH:mm',

  DATE: '',
  TIME: '00:00:00',
  DATE_FORMAT: 'yyyy-MM-DD',
  TIME_FORMAT: 'HH:mm',
  DIALOG_WIDTH: 450,
  OK_TEXT: 'Übernehmen',
  CLEAR_TEXT: 'Zurücksetzen',
};

@Component({
  name: 'v-datetime-picker'
})
export default class DatetimePicker extends Vue {
  @Model('input', {type: [Date, String]}) readonly datetime!: Date | string;

  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Boolean, default: false}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly loading!: boolean;
  @Prop({type: Boolean, default: true}) readonly readonly!: boolean;
  @Prop({type: String, default: ''}) readonly label!: string;

  @Prop({type: Number, default: DEFAULTS.DIALOG_WIDTH}) readonly dialogWidth!: number;
  @Prop({type: String, default: DEFAULTS.DATE_FORMAT}) readonly dateFormat!: string;
  @Prop({type: String, default: DEFAULTS.TIME_FORMAT}) readonly timeFormat!: string;
  @Prop({type: String, default: DEFAULTS.OK_TEXT}) readonly okText!: string;
  @Prop({type: String, default: DEFAULTS.CLEAR_TEXT}) readonly clearText!: string;
  @Prop({type: String, default: DEFAULTS.DISPLAY_FORMAT}) readonly displayFormat!: string;

  // Other field styles
  @Prop({type: Boolean, default: false}) readonly dark!: boolean;
  @Prop({type: String, default: 'primary'}) readonly color!: boolean;

  /**
   *  Component specific props
   *  See documentation
   *    - https://vuetifyjs.com/en/components/text-fields
   *    - https://vuetifyjs.com/en/components/date-pickers
   *    - https://vuetifyjs.com/en/components/time-pickers
   */
  // @Prop({type: Object, required: false, default: {}}) readonly textFieldProps!: {};
  // @Prop({type: Object, required: false, default: {}}) readonly datePickerProps!: {};
  // @Prop({type: Object, required: false, default: {}}) readonly timePickerProps!: {};

  // UI influence
  /**
   * Disables time edition in dialog
   */
  @Prop({type: Boolean, default: false}) readonly disableTime!: boolean;
  /**
   * Disables date edition in dialog
   */
  @Prop({type: Boolean, default: false}) readonly disableDate!: boolean;

  display = false;
  activeTab = 0
  date = DEFAULTS.DATE
  time = DEFAULTS.TIME

  dateTimeStringLocal = ''

  dateRules = {
    notEmpty: (value: string) => !!value || 'Dieses Feld ist erforderlich',
    format: (value: string) => (
        moment(value, this.displayFormat, true).isValid() ||
        moment(value, this.displayFormat + ":ss", true).isValid()
    ) || `Das Datum-Format stimmt nicht. Format: ${this.displayFormat}.\nSekunden werden nicht berücksichtigt.`
  };

  // Computed
  get dateTimeFormat() {
    return this.dateFormat + ' ' + this.timeFormat
  }

  get defaultDateTimeFormat() {
    return DEFAULTS.DATE_FORMAT + ' ' + DEFAULTS.TIME_FORMAT
  }

  get formattedDatetime() {
    const formatted = this.selectedDatetime ? moment(this.selectedDatetime).format(this.displayFormat) : ''
    this.dateTimeStringLocal = formatted
    return formatted
  }

  get selectedDatetime() {
    if (this.date && this.time) {
      let datetimeString = this.date + ' ' + this.time
      if (this.time.length === 5) {
        datetimeString += ':00'
      }
      return moment(datetimeString).format(this.defaultDateTimeFormat)
    } else {
      return null
    }
  }

  // Watcher
  @Watch('datetime')
  dateTimeChanged() {
    this.init()
  }

  @Watch('disableDate')
  displayChanged() {
    if (this.disableDate) {
      this.activeTab = 1
    } else {
      this.activeTab = 0
    }
  }

  @Watch('display')
  menuToggled(isShown: boolean) {
    if (!isShown) {
      if (this.$refs.timer) {
        (this.$refs.timer as Record<string, any>).selectingHour = true
      }
    } else {
      this.dateTimeStringLocal = this.formattedDatetime
    }
  }

  // Lifecycle
  created() {
    this.init()
  }

  // Methods
  init() {
    // Case: v-model from campaign dialog outside (here prop) is null
    // reset local data which might be set earlier as component
    // is created only once and not again and again
    if (!this.datetime) {
      this.date = DEFAULTS.DATE
      this.time = DEFAULTS.TIME
      return
    }

    let initDateTime
    if (this.datetime instanceof Date) {
      initDateTime = this.datetime
    } else {
      // see https://stackoverflow.com/a/9436948
      initDateTime = moment(this.datetime).format(this.dateTimeFormat)
    }
    this.date = moment(initDateTime).format(DEFAULTS.DATE_FORMAT)
    this.time = this.selectedDatetime ? moment(initDateTime).format(DEFAULTS.TIME_FORMAT) : DEFAULTS.TIME
    this.dateTimeStringLocal = this.formattedDatetime
  }

  okHandler() {
    this.resetPicker()
    this.$emit('input', this.selectedDatetime)
  }

  clearHandler() {
    this.resetPicker()
    this.date = DEFAULTS.DATE
    this.time = DEFAULTS.TIME
    this.$emit('input', null)
  }

  now() {
    this.resetPicker()
    this.$emit('input', moment().format(this.defaultDateTimeFormat))
  }

  resetPicker() {
    this.display = false
    if (this.disableDate) {
      this.activeTab = 1
    } else {
      this.activeTab = 0
    }

    // Not the prettiest but no predefined Vuetify type exists
    // and i don't write an interface for this
    if (this.$refs.timer) {
      (this.$refs.timer as Record<string, any>).selectingHour = true
    }
  }

  showTimePicker() {
    if (!this.disableTime) {
      this.activeTab = 1
    }
  }
}
