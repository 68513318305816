import {ORGANIZATIONS} from "@/enum/Organizations";
import {PRODUCTS} from "@/enum/Products";

export const PATHS = {
    LOGIN: '/',
    CAMPAIGNS: '/campaigns',
    USERS: '/users',
    DOCUMENTS: '/documents',
    TICKETS: '/lose',
    QUICKTIPPS: '/quicktipps',
    MAINTENANCE: '/maintenance',
    PAGEMANAGEMENT: '/pages',
    REPORTS: '/reports',
    APPVERSIONS: '/appversions',
    SWITCHDATES: '/switchdates',
    PUSH: '/push',
};

export function getLandingPageForOrganizationId(id: number | undefined, isOnline: boolean): string {
    if (
        ORGANIZATIONS.BE.validCodes.get(PRODUCTS.OA) === id ||
        ORGANIZATIONS.BE.validCodes.get(PRODUCTS.SVA) === id ||
        ORGANIZATIONS.SH.validCodes.get(PRODUCTS.OA) === id ||
        ORGANIZATIONS.SH.validCodes.get(PRODUCTS.SVA) === id
    ) {
        if (isOnline) {
            return PATHS.CAMPAIGNS;
        } else {
            return PATHS.SWITCHDATES;
        }
    }
    return PATHS.CAMPAIGNS;
}
