
import Vue from "vue";
import {Component, Watch} from 'vue-property-decorator';
import VDatetimePicker from "@/components/datetimepicker/DatetimePicker.vue";
import {namespace} from "vuex-class";
import {SwitchDatesModel} from "@/stores/switchdates/types";
import moment from "moment";
import {getCodeForOrganizationAndProduct, Organization, ORGANIZATIONS} from "@/enum/Organizations";
import {OrgConfig, User} from "@/stores/auth/types";
import {variant} from "@/api/base";
import {ROLES} from "@/enum/Roles";

const authModule = namespace('auth');
const switchDatesModule = namespace('switchdates');

@Component({
  name: 'SwitchDates',
  components: {
    VDatetimePicker
  },
})
export default class SwitchDates extends Vue {
  @authModule.Getter('gesellschaft') organization!: Organization;
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('user') user!: User;

  @switchDatesModule.State('switchDates') state!: SwitchDatesModel;

  @switchDatesModule.Action('getAll') fetchSwitchDates!: Function;
  @switchDatesModule.Action('postEjSwitch') postEjSwitch!: Function;
  @switchDatesModule.Action('postEjFirstDrawNew') postEjFirstDraw!: Function;
  @switchDatesModule.Action('postFeeUpdate') postFeeUpdate!: Function;

  datetimeEjSwitch: Date | null = null;
  datetimeEjFirstDraw: Date | null = null;
  datetimeFeeUpdate: Date | null = null;

  mounted() {
    if (this.organization != ORGANIZATIONS.MOBIVENTION) {
      this.fetchSwitchDates(getCodeForOrganizationAndProduct(this.organization, this.config.product_id, variant))
    }
  }

  @Watch('state')
  onDatesChanged(val: SwitchDatesModel) {
    if (this.state.switch_time) {
      this.datetimeEjSwitch = new Date(this.state.switch_time)
    }
    if (this.state.switch_time_first_drawing) {
      this.datetimeEjFirstDraw = new Date(this.state.switch_time_first_drawing)
    }
    if (this.state.fee_update) {
      this.datetimeFeeUpdate = new Date(this.state.fee_update)
    }
  }

  save() {
    const millisEjSwitch = moment(this.datetimeEjSwitch).valueOf();
    const millisEjFirstDraw = moment(this.datetimeEjFirstDraw).valueOf();
    const millisFeeUpdate = moment(this.datetimeFeeUpdate).valueOf();

    if (this.config.allow_ej_switch && millisEjSwitch > 0) {
      this.postEjSwitch(
          {
            switch_time: millisEjSwitch,
            gesNr: getCodeForOrganizationAndProduct(this.organization, this.config.product_id, variant)
          }
      );
    }
    if (this.config.allow_ej_switch && millisEjFirstDraw > 0) {
      this.postEjFirstDraw(
          {
            switch_time: millisEjFirstDraw,
            gesNr: getCodeForOrganizationAndProduct(this.organization, this.config.product_id, variant)
          }
      )
    }
    if (this.config.has_scheduled_fee_update && millisFeeUpdate > 0) {
      this.postFeeUpdate(
          {
            switch_time: millisFeeUpdate,
            gesNr: getCodeForOrganizationAndProduct(this.organization, this.config.product_id, variant)
          }
      )
    }
  }

  disablesSwitchDatesEditation(): boolean {
    switch (this.user.role.role_id) {
      case ROLES.SUPERADMIN.role_id:
      case ROLES.ADMIN.role_id:
        return false
      default:
        return true
    }
  }
}
