
import Vue from "vue";
import {Component, Watch} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {convertGameTypeFromString, GAMETYPES} from "@/enum/GameTypes";
import {OrgConfig} from "@/stores/auth/types";
import {getCodeForOrganizationAndProduct, Organization, ORGANIZATIONS} from "@/enum/Organizations";
import {PushDialogModel} from "@/stores/push/types";
import {convertPushTypeFromString, convertPushTypeFromValue, PUSHTYPES} from "@/enum/PushTypes";
import {variant} from "@/api/base";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {productForIdentifier, PRODUCTS} from "@/enum/Products";

const authModule = namespace('auth');
const pushModule = namespace('push');

@Component({
  name: 'PushDialog',
  components: {}
})

export default class PushDialog extends Vue {
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;

  @pushModule.State('dialogState') state!: PushDialogModel;
  @pushModule.Mutation('dismissDialog') dismiss!: Function;

  @pushModule.Action('send_test_push') send_assemble_new!: Function;
  @pushModule.Action('sendEditorialPush') sendEditorialPush!: Function;
  @pushModule.Action('repeat_push') send_repeat!: Function;

  selectableGameTypes: Array<string> = []
  selectablePushTypes: Array<string> = []

  dialogTitle = "";

  title = '';
  titleCharLimit = 60;
  message = '';
  messageCharLimit = 180;

  token = ""
  game_type = "";
  push_type = "";
  os = "";

  typeGS = GAMETYPES.GLUECKSSPIRALE.displayTitle;
  typeKENO = GAMETYPES.KENO.displayTitle;
  typeEDITORIAL = PUSHTYPES.EDITORIAL.displayTitle;

  rules = {
    notEmpty: (value: string) => !!value || "Dieses Feld ist erforderlich",
    lengtTitle: (value: string) => value.length <= this.titleCharLimit || 'Maximale Länge überschritten',
    lengtMessage: (value: string) => value.length <= this.messageCharLimit || 'Maximale Länge überschritten'
  };

  emptyRule = this.rules.notEmpty

  get userCanWrite() {
    return this.state.isNew
  }

  get pushTypes() {
    this.selectablePushTypes = [];

    this.selectablePushTypes.push(PUSHTYPES.REMINDER.displayTitle);
    this.selectablePushTypes.push(PUSHTYPES.JACKPOT.displayTitle);
    this.selectablePushTypes.push(PUSHTYPES.GAME_RESULTS.displayTitle);
    this.selectablePushTypes.push(PUSHTYPES.GAME_RESULT_ODDS.displayTitle);

    if (this.config.has_editorial_push) {
      this.selectablePushTypes.push(PUSHTYPES.EDITORIAL.displayTitle);
    }

    return this.selectablePushTypes
  }

  @Watch("push_type")
  onPushTypeChanged(val: string, oldVal: string) {
    const pushType = convertPushTypeFromString(val)

    this.selectableGameTypes = [];

    switch (pushType) {
      case PUSHTYPES.REMINDER:
        this.selectableGameTypes.push(GAMETYPES.LOTTO.displayTitle);
        this.selectableGameTypes.push(GAMETYPES.EUROJACKPOT.displayTitle);
        this.selectableGameTypes.push(GAMETYPES.GLUECKSSPIRALE.displayTitle);
        break;
      case PUSHTYPES.JACKPOT:
        this.selectableGameTypes.push(GAMETYPES.LOTTO.displayTitle);
        this.selectableGameTypes.push(GAMETYPES.EUROJACKPOT.displayTitle);
        break;
      case PUSHTYPES.GAME_RESULTS:
        this.selectableGameTypes.push(GAMETYPES.LOTTO.displayTitle);
        this.selectableGameTypes.push(GAMETYPES.EUROJACKPOT.displayTitle);
        this.selectableGameTypes.push(GAMETYPES.GLUECKSSPIRALE.displayTitle);
        break;
      case PUSHTYPES.GAME_RESULT_ODDS:
        this.selectableGameTypes.push(GAMETYPES.LOTTO.displayTitle);
        this.selectableGameTypes.push(GAMETYPES.EUROJACKPOT.displayTitle);
        break;
    }

    // ST special
    if (this.organization === ORGANIZATIONS.ST && pushType == PUSHTYPES.JACKPOT || pushType == PUSHTYPES.GAME_RESULTS || pushType == PUSHTYPES.GAME_RESULT_ODDS) {
      this.selectableGameTypes.push(GAMETYPES.BINGO.displayTitle)
    } else {
      if (pushType == PUSHTYPES.REMINDER || pushType == PUSHTYPES.GAME_RESULTS) {
        this.selectableGameTypes.push(GAMETYPES.KENO.displayTitle)
      }
    }
  }

  getGameTypeRules() {
    if (this.push_type == PUSHTYPES.EDITORIAL.displayTitle) {
      return true
    } else {
      return this.emptyRule(this.game_type)
    }
  }

  @Watch('state.visible')
  onShowChanged() {
    if (this.state.isNew) {
      this.dialogTitle = 'Neue Test Push'
    } else {
      this.dialogTitle = 'Push wiederholen';

      const gameType = convertGameTypeFromString(this.state.push?.game_type)?.displayTitle ?? "";
      this.selectableGameTypes = [gameType]
      this.game_type = gameType

      const pushType = convertPushTypeFromValue(this.state.push?.notification_type)?.displayTitle ?? ""
      this.selectablePushTypes = [pushType]
      this.push_type = pushType

      this.message = this.state.push?.text_message ?? ""
    }
  }

  onConfirm() {
    this.handleAction()
  }

  handleAction() {
    if (((this.$refs.pushForm as HTMLFormElement).validate())) {
      if (this.state.isNew) {
        if (this.push_type === this.typeEDITORIAL) {
          QuestionStore.poseQuestion(
              "Senden bestätigen",
              `Push wirklich senden? Dies kann nicht rückgängig gemacht werden`,
              ['Ja', 'Nein'],
              -1
          ).then((answerIndex) => {
            if (answerIndex === 0) {
              const data = {
                "push_title": this.preparePushTitle(),
                "push_text": this.message,
                "fcm_tokens": this.token,
                "only_os": this.os
              }

              this.sendEditorialPush(data);
              // onCancel() after each call because this one is behind callback
              this.onCancel()
            }
          })
        } else {
          this.send_assemble_new(
              {
                "token": this.token,
                "gesellschafts_code": getCodeForOrganizationAndProduct(this.organization, this.config.product_id, variant),
                "game_type": convertGameTypeFromString(this.game_type)?.altServerValue ?? "",
                "push_type": convertPushTypeFromString(this.push_type)?.name ?? "",
                "os": this.os,
              }
          )
          this.onCancel()
        }
      } else {
        // Take everything from model
        this.send_repeat(
            {
              "game_type": this.state.push?.data_message?.gameType,
              "push_type": this.state.push?.data_message?.pushType,
              "deeplink": this.state.push?.data_message?.deeplink,
              "drawing_day": this.state.push?.data_message?.drawingDay,
              "next_drawing": this.state.push?.data_message?.nextDrawing,
              "value": this.state.push?.data_message?.value,
              // has support for multiple tokens but for now don't advertise in UI as it is an textfield with no restrictions
              "fcm_tokens": this.token,
              "only_os": this.os,
            }
        )
        this.onCancel()
      }
    }
  }

  onCancel() {
    this.title = "";
    this.message = "";
    this.token = "";
    this.game_type = "";
    this.push_type = "";
    this.os = "";
    this.dismiss()
  }

  isNumber(value: string) {
    return !isNaN(parseFloat(value)) && !isNaN(Number(value))
  }

  preparePushTitle(): string {
    const product = productForIdentifier(this.config.product_id);

    switch (this.organization) {
      case ORGANIZATIONS.BY:
        if (product == PRODUCTS.OA) {
          return "LOTTO Bayern App News"
        } else {
          return "LOTTO Bayern: SÄPP News"
        }
      case ORGANIZATIONS.RP:
        return "LOTTO Rheinland-Pfalz: LoRA News";
      case ORGANIZATIONS.SL:
        return "ServiceApp: News";
      case ORGANIZATIONS.SH:
        return "LOTTO Schleswig-Holstein: LOTTA News";
      case ORGANIZATIONS.ST:
        return "LOTTO Sachsen-Anhalt: LOTTI News";
      case ORGANIZATIONS.BE:
        return "LOTTO Berlin: Berta News";
      default:
        // apparently push title can't be missing and can't be empty string
        return "-";
    }
  }
}
