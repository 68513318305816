export const NAMES = {
    LOGIN: 'LOGIN',
    CAMPAIGNS: 'CAMPAIGNS',
    USERS: 'USERS',
    DOCUMENTS: 'DOCUMENTS',
    TICKETS: 'TICKETS',
    QUICKTIPPS: 'QUICKTIPPS',
    MAINTENANCE: 'MAINTENANCE',
    PAGEMANAGEMENT: 'PAGEMANAGEMENT',
    REPORTS: 'REPORTS',
    APPVERSIONS: 'APPVERSIONS',
    SWITCHDATES: 'SWITCHDATES',
    PUSH: 'PUSH',
};
