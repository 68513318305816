
import timeUtil from '@/utils/time'
import {Component, Prop, Vue} from "vue-property-decorator";
import {CampaignImage, CampaignModel} from "@/stores/campaigns/types";
import {namespace} from "vuex-class";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {Organization, ORGANIZATIONS} from "@/enum/Organizations";
import {deeplinkTypeForAction, maskedDeeplinkActionForAction} from "@/enum/Deeplinks";
import {ImageDimension, OrgConfig} from "@/stores/auth/types";
import {productForIdentifier} from "@/enum/Products";
import {DIMENS} from "@/enum/Dimension";

const authModule = namespace('auth');
const campaignModule = namespace('campaigns');

@Component({
  name: 'campaign-list-row'
})
export default class CampaignListRow extends Vue {
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;
  @authModule.Getter('imageDimensions') dimens!: Array<ImageDimension> | null;

  @campaignModule.Mutation('showCampaignDialog') showDialog!: Function;
  @campaignModule.Action('delete') deleteCampaign!: Function;

  @Prop({type: Object, required: true, default: {}}) readonly campaign!: CampaignModel;

  dimenIdPhone = DIMENS.CAMPAIGN.identifier
  dimenIdTablet = DIMENS.CAMPAIGN_TABLET.identifier

  get opacity(): string {
    return timeUtil.objectIsActive(this.campaign.start, this.campaign.end) ? '' : 'opacity: 0.5'
  }

  get textClass(): string {
    return timeUtil.objectIsActive(this.campaign.start, this.campaign.end) ? 'black--text' : 'grey--text'
  }

  get CampaignSrc() {
    return this.campaign ? this.getImageIfExisting(DIMENS.CAMPAIGN.identifier)?.url ?? '' : ''
  }

  formatDate(date: string): string {
    return timeUtil.formattedDate(date)
  }

  editClicked() {
    this.showDialog({campaign: this.campaign, isNew: false})
  }

  deleteClicked() {
    QuestionStore.poseQuestion(
        null,
        `Kampagne ${this.campaign.headline} wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex: number) => {
      if (answerIndex === 0) {
        this.deleteCampaign({campaignId: this.campaign.id})
      }
    })
  }

  openImage(url: string) {
    window.open(url)
  }

  hasDeeplinks() {
    return (this.config.content_deeplink || this.config.url_deeplink || this.config.quicktipp_deeplink);
  }

  hasAdPager(): boolean {
    const product = productForIdentifier(this.config.product_id);
    //organization check not required here but use it for now as RP is only org with it
    if (product && this.organization === ORGANIZATIONS.RP) {
      return !this.organization.siteConfig.get(product)?.hasSingularCampaign ?? false
    }
    return false
  }

  getDeeplinkHeadline(): string {
    //headline is mirrored in deeplink?.headline
    return this.campaign.headline ?? ''
  }

  getDeeplinkSubline(): string {
    //description is mirrored in deeplink?.subline
    return this.campaign.description ?? ''
  }

  getDeeplinkButtonname(): string {
    return this.campaign.deeplink?.buttonname ?? ''
  }

  isOrgUnderBerlin(): boolean {
    return this.organization === ORGANIZATIONS.RP ||
        this.organization === ORGANIZATIONS.SL ||
        this.organization === ORGANIZATIONS.SH ||
        this.organization === ORGANIZATIONS.ST ||
        this.organization === ORGANIZATIONS.BE ||
        this.organization === ORGANIZATIONS.MV
  }

  isBrandenburg() {
    return this.organization === ORGANIZATIONS.BB;
  }

  mapDeeplinkType(): string | null {
    return deeplinkTypeForAction(this.campaign.deeplink?.action ?? null, true)
  }

  mapDeeplinkAction(): string | null {
    return maskedDeeplinkActionForAction(this.campaign.deeplink?.action ?? null)
  }

  getImageIfExisting(identifier: string): CampaignImage | undefined {
    return this.campaign.images?.find(e => e.type === identifier)
  }
}
