
import {Component, Vue, Watch} from "vue-property-decorator";
import HomeDrawer from "@/components/HomeDrawer.vue";
import {NAMES} from "@/constants/names";
import {namespace} from "vuex-class";
import {OrgConfig, User} from "@/stores/auth/types";
import {ROLES} from "@/enum/Roles";
import {Organization, ORGANIZATIONS} from "@/enum/Organizations";
import {productForIdentifier, PRODUCTS} from "@/enum/Products";
import {variant, version} from "@/api/base";
import {VALUES} from "@/constants/values";

const authModule = namespace('auth');
const infoModule = namespace('info');

@Component({
  components: {
    HomeDrawer
  },
})
export default class HomeAppBar extends Vue {
  @authModule.Mutation('resetState') resetState!: Function;
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;
  @authModule.Getter('user') user!: User;
  @infoModule.State('loading') loading!: boolean;

  loadActive = false;
  drawer = null;
  selectedMoreItem = false

  get orgAsset() {
    switch (this.organization) {
      case ORGANIZATIONS.BY:
        return require('@/assets/logo_lotto_bayern.png');
      case ORGANIZATIONS.BW:
        return require('@/assets/logo_lotto_bw.png');
      case ORGANIZATIONS.BB:
        return require('@/assets/logo_lotto_brandenburg.png');
      case ORGANIZATIONS.RP:
        return require('@/assets/logo_lotto_rlp.png');
      case ORGANIZATIONS.SL:
        return require('@/assets/logo_lotto_saartoto.png');
      case ORGANIZATIONS.SH:
        return require('@/assets/logo_lotto_sh.png');
      case ORGANIZATIONS.ST:
        return require('@/assets/logo_lotto_st.png');
      case ORGANIZATIONS.BE:
        return require('@/assets/logo_lotto_berlin.png');
      case ORGANIZATIONS.MV:
        return require('@/assets/logo_lotto_mv.png');
      default:
        return require('@/assets/logo_lotto_allgemein.jpeg');
    }
  }

  get appIcon() {
    const product = productForIdentifier(this.config.product_id);

    switch (this.organization) {
      case ORGANIZATIONS.BY:
        if (product == PRODUCTS.OA) {
          if (variant == "live") {
            return require('@/assets/appicons/online/live/by_online.png');
          } else {
            return require('@/assets/appicons/online/test/by_online.png');
          }
        } else {
          if (variant == "live") {
            return require('@/assets/appicons/service/live/by.png');
          } else {
            return require('@/assets/appicons/service/test/by.png');
          }
        }
      case ORGANIZATIONS.BW:
        if (variant == "live") {
          return require('@/assets/appicons/service/live/bw.png');
        } else {
          return require('@/assets/appicons/service/test/bw.png');
        }
      case ORGANIZATIONS.BB:
        if (variant == "live") {
          return require('@/assets/appicons/service/live/bb.png');
        } else {
          return require('@/assets/appicons/service/test/bb.png');
        }
      case ORGANIZATIONS.RP:
        if (product == PRODUCTS.OA) {
          if (variant == "live") {
            return require('@/assets/appicons/online/live/rp_online.png');
          } else {
            return require('@/assets/appicons/online/test/rp_online.png');
          }
        } else {
          if (variant == "live") {
            return require('@/assets/appicons/service/live/rp.png');
          } else {
            return require('@/assets/appicons/service/test/rp.png');
          }
        }
      case ORGANIZATIONS.SL:
        if (product == PRODUCTS.OA) {
          if (variant == "live") {
            return require('@/assets/appicons/online/live/sl_online.png');
          } else {
            return require('@/assets/appicons/online/test/sl_online.png');
          }
        } else {
          if (variant == "live") {
            return require('@/assets/appicons/service/live/sl.png');
          } else {
            return require('@/assets/appicons/service/test/sl.png');
          }
        }
      case ORGANIZATIONS.SH:
        if (product == PRODUCTS.OA) {
          if (variant == "live") {
            return require('@/assets/appicons/online/live/sh_online.png');
          } else {
            return require('@/assets/appicons/online/test/sh_online.png');
          }
        } else {
          if (variant == "live") {
            return require('@/assets/appicons/service/live/sh.png');
          } else {
            return require('@/assets/appicons/service/test/sh.png');
          }
        }
      case ORGANIZATIONS.ST:
        if (product == PRODUCTS.OA) {
          if (variant == "live") {
            return require('@/assets/appicons/online/live/st_online.png');
          } else {
            return require('@/assets/appicons/online/test/st_online.png');
          }
        } else {
          if (variant == "live") {
            return require('@/assets/appicons/service/live/st.png');
          } else {
            return require('@/assets/appicons/service/test/st.png');
          }
        }
      case ORGANIZATIONS.BE:
        if (product == PRODUCTS.OA) {
          if (variant == "live") {
            return require('@/assets/appicons/online/live/be_online.png');
          } else {
            return require('@/assets/appicons/online/test/be_online.png');
          }
        } else {
          if (variant == "live") {
            return require('@/assets/appicons/service/live/be.png');
          } else {
            return require('@/assets/appicons/service/test/be.png');
          }
        }
      case ORGANIZATIONS.MV:
        if (variant == "live") {
          return require('@/assets/appicons/online/live/mv_online.png');
        } else {
          return require('@/assets/appicons/online/test/mv_online.png');
        }
      default:
        return require('@/assets/logo_lotto_allgemein.jpeg');
    }
  }

  get items() {
    return this.prepareNavigationItems(false)
  }

  get moreItems() {
    return this.prepareNavigationItems(true)
  }

  // returns string array of page names
  prepareNavigationItems(forOverflow: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let tabs: Array<any> = [];

    if (this.config.has_campaign_management) {
      tabs.push(NAMES.CAMPAIGNS)
    }
    if (this.config.has_pdf_management) {
      tabs.push(NAMES.DOCUMENTS)
    }
    if (this.config.has_lot_management) {
      tabs.push(NAMES.TICKETS)
    }
    if (this.config.has_simple_quicktipps) {
      tabs.push(NAMES.QUICKTIPPS)
    }
    if (this.config.has_maintenance) {
      tabs.push(NAMES.MAINTENANCE)
    }
    if (this.config.has_page_management) {
      tabs.push(NAMES.PAGEMANAGEMENT)
    }
    if (this.config.has_pdf_management && this.user.role.identifier == ROLES.ADMIN.identifier) {
      tabs.push(NAMES.REPORTS)
    }
    if (this.config.app_version) {
      tabs.push(NAMES.APPVERSIONS)
    }
    if (this.config.has_switch_management) {
      tabs.push(NAMES.SWITCHDATES)
    }
    if (this.user.role.identifier == ROLES.SUPERADMIN.identifier || this.config.has_editorial_push) {
      tabs.push(NAMES.PUSH)
    }
    //Show user always on the most right
    if (this.config.has_user_management && this.user.role.identifier != ROLES.MARKETING.identifier) {
      tabs.push(NAMES.USERS)
    }

    if (forOverflow) {
      if (tabs.length >= VALUES.MAX_TABS_IN_NAVIGATION) {
        tabs = tabs.slice(VALUES.MAX_TABS_IN_NAVIGATION, tabs.length);
      } else {
        tabs = []
      }
    } else {
      if (tabs.length >= VALUES.MAX_TABS_IN_NAVIGATION) {
        tabs = tabs.slice(0, VALUES.MAX_TABS_IN_NAVIGATION);
      }
    }

    return tabs
  }

  navigate(item: string) {
    this.selectedMoreItem = true
    this.$router.push(item.toLocaleLowerCase());
  }

  @Watch('loading')
  onLoadingChanged(val: boolean, oldVal: boolean) {
    this.loadActive = val
  }

  logout() {
    this.resetState()
  }

  translate(pageName: string) {
    switch (pageName) {
      case NAMES.CAMPAIGNS:
        return "Kampagnen";
      case NAMES.USERS:
        return "Nutzer";
      case NAMES.DOCUMENTS:
        return "Dokumente";
      case NAMES.TICKETS:
        return "Lose";
      case NAMES.QUICKTIPPS:
        return "Quicktipps";
      case NAMES.MAINTENANCE:
        return "Wartungsfenster";
      case NAMES.PAGEMANAGEMENT:
        return "Seitenverwaltung"
      case NAMES.REPORTS:
        return "Absturzberichte";
      case NAMES.APPVERSIONS:
        return "Appversion";
      case NAMES.SWITCHDATES:
        return "Umstellungsdaten";
      case NAMES.PUSH:
        return "Pushnachrichten";
    }
  }

  getFrontendVersion(): string {
    return version
  }
}
