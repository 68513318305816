
import {Component} from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: 'Maintenance',
  components: {
    //
  },
})
export default class Maintenance extends Vue {

}
